import React from "react"
import PropTypes from "prop-types"
import { RecoilRoot } from 'recoil'
import NavbarTwoCustom from './_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
//import { ToastProvider } from 'react-toast-notifications';
//import Footer from './_App/Footer';
import FooterMin from './_App/FooterMin';
import GoTop from './_App/GoTop';  //   <NavbarTwoCustom />
import Web3 from 'web3';
//import niftyriots from '../contracts/Niftyriots.json'
//import axios from 'axios';

class PreSaleEditPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            account: props.presale.ethaddress,
            current_account: "",
            amount: props.presale.amount
        };

        console.log("-----------------------------")
        console.log(props.presale)
        console.log("-----------------------------")
        console.log(props.authenticity_token)

    }

    //mounting the main Element
    async componentDidMount() {
        await this.loadWeb3(this.props.dispatch)
    }

    //network and web3 logic

    async loadWeb3(dispatch) {
        console.log("loadWeb3")
        if (window.ethereum) {
            console.log("Starting up....")
            const web3 = new Web3(window.ethereum)    // METAMASK CONNECTION
            const netId = await web3.eth.net.getId()     // gets the value of the network
            const enabledWeb3 = await ethereum.enable();
            const accounts = await web3.eth.getAccounts()

            this.handleAmountChange = this.handleAmountChange.bind(this);

            //load balance
            if (typeof accounts[0] !== 'undefined') {
                console.log("-----getting balance-----")
                const balance = await web3.eth.getBalance(accounts[0])
                console.log("-----balance-----")
                console.log(balance)
                console.log("balance", web3.utils.fromWei(balance, 'ether'), "ETH")

                this.setState({ current_account: accounts[0], balance: balance, web3: web3 })

            } else {
                //window.alert('Please login with MetaMask')

            }

        } else {
            window.alert('Please install MetaMask')
        }
    }   // END OF LOADING block chain data

    handleAmountChange(event) {
        console.log(event)
        this.setState({ amount: event.target.value });
    }



    render() {

        return (
            <React.Fragment>
                <RecoilRoot>
                    <NavbarTwoCustom />

                    <section className="what-we-do-area bg-fafafb pt-100 pb-100">

                        <div className="container ">

                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <div className="d-flex flex-row  pb-2 justify-content-center">
                                        <h1>Editing Presale</h1>
                                    </div>
                                </div>
                                <div className="col-10">
                                    <form action={"/presales/"+this.props.presale.id} method="post" onSubmit={(e) => {
                                        //e.preventDefault() 
                                        //       required
                                        //      disabled
                                        //      readOnly
                                        //                         ref={(input) => { this.ethaddress = input }}
                                        console.log(this.ethaddress.value)
                                        console.log(this.amount.value)
                                        console.log(this.authenticity_token.value)
                                    }}>
                                        <div className="form-row justify-content-center">
                                            <input
                                                type='hidden'
                                                name="authenticity_token"
                                                value={this.props.authenticity_token}
                                                ref={(input) => { this.authenticity_token = input }}

                                            />
                                            <input
                                            type='hidden'
                                            name="_method"
                                                value="patch"
                                                ref={(input) => { this._method = input }}

                                            />

                                            <div className="form-group col-7 mb-1">
                                                <label htmlFor="presale-ethaddress">ETH Address</label>
                                                <input
                                                    id="presale-ethaddress"
                                                    type='text'
                                                    className="form-control mb-3"
                                                    name="presale[ethaddress]"
                                                    placeholder='ETH address'
                                                    value={this.state.account}
                                                    required
                                                    disabled
                                                    readOnly
                                                />
                                            </div>
                                            <div className="form-group col-7 mb-1">
                                                <label htmlFor="presale-amount">Amount</label>
                                                <input
                                                    id="presale-amount"
                                                    type='number'
                                                    className="form-control mb-3"
                                                    placeholder={'e.g. 1-'+this.props.presale_limit}
                                                    name="presale[amount]"
                                                    max={this.props.presale_limit}
                                                    min='1'
                                                    value={this.state.amount}
                                                    onChange={this.handleAmountChange}
                                                    ref={(input) => { this.amount = input }}
                                                    required />


                                            </div>
                                            <div className="form-group col-7">
                                                <input type='submit' className='btn btn-block default-btn-custom' value='Create Presale' />
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </section>




                    <GoTop />
                </RecoilRoot>
                <FooterMin fixedBottom={true} S3_ADDRESS={this.props.S3_ADDRESS} user_signed_in={this.props.user_signed_in} />
            </React.Fragment>)
    }
}

PreSaleEditPage.propTypes = {
    S3_ADDRESS: PropTypes.string
};

export default PreSaleEditPage