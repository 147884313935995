import React from 'react';
import NavbarTwoCustom from './_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
import FooterMin from './_App/FooterMin';
import GoTop from './_App/GoTop';  //   <NavbarTwoCustom />
import Map from './Common/Map';
import {  RecoilRoot } from 'recoil'


class MapPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <RecoilRoot>
          <NavbarTwoCustom />
          <Map  S3_ADDRESS={this.props.S3_ADDRESS} />
          <FooterMin fixedBottom={false} />
          <GoTop />
        </RecoilRoot>
      </React.Fragment>
    );
  }
}

export default MapPage
