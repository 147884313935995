import React from "react"
import PropTypes from "prop-types"

import { ToastProvider } from 'react-toast-notifications';
import PreSaleInsPage from './PreSaleInsPage'


class PreSaleInsPageWrap extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (

            <ToastProvider autoDismiss autoDismissTimeout={10000}>
                <PreSaleInsPage
                    S3_ADDRESS={this.props.S3_ADDRESS}
                    eth_address={this.props.eth_address}
                    user_signed_in={this.props.user_signed_in} />
            </ToastProvider>
        )
    }
}

PreSaleInsPageWrap.propTypes = {
    S3_ADDRESS: PropTypes.string
};

export default PreSaleInsPageWrap