import React from 'react';
//import PageBanner from '../components/Common/PageBanner';
import Lightbox from 'react-image-lightbox';
import RenamingBoxes from './RenamingBox';
import '../stylesheets/custom.css';

//for web3


import Web3 from 'web3';
import niftyriots from '../contracts/Niftyriots.json'
import axios from 'axios';


import { ToastProvider } from 'react-toast-notifications';





class OwnCollectionv2 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      account: '',
      contract: null,
      totalSupply: 0,
      sizeToBuy: 0,
      currentPrice: 10,
      tokenIdsOwned: [],
      tokenIdsOwnedNames: [],
      nftowner: '',
      nftbal: 0,
      date: new Date(),
      tokenURI: "",
      arrayfromapi: []

    };
    this.handlerForms = this.handlerForms.bind(this);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };

  }


  //mounting the main Element
  async componentDidMount() {
    await this.loadWeb3(this.props.dispatch)

  }

  handlerForms(status){
    this.props.handlerForms(status)
  }

  //network and web3 logic

  async loadWeb3(dispatch) {
    console.log("loadWeb3")

    const lst = [];
    const populateData2 = (data) => {

      /*adding this object to the state*/
      this.setState({
        arrayfromapi: [...this.state.arrayfromapi, data]
      });
      this.props.setEmpty(false);
    }



    if (window.ethereum) {

      console.log("Starting up Collectopm....")
      const web3 = new Web3(window.ethereum)    // METAMASK CONNECTION
      const netId = await web3.eth.net.getId()     // gets the value of the network
      const enabledWeb3 = await ethereum.enable();
      const accounts = await web3.eth.getAccounts()

      //load balance
      if (typeof accounts[0] !== 'undefined') {
        console.log("-----getting balance-----")
        const balance = await web3.eth.getBalance(accounts[0])
        console.log("-----balance-----")
        console.log(balance)
        console.log("balance", web3.utils.fromWei(balance, 'ether'), "ETH")

        this.setState({ account: accounts[0], balance: balance, web3: web3 })

      } else {
        //window.alert('Please login with MetaMask')
      }


      //load contracts
      try {


        const contract = new web3.eth.Contract(niftyriots.abi, niftyriots.networks[netId].address)  // comes from ABI json file

        this.setState({ contract }) /// shortcut for {contract : contract}

        const totalSupply = await contract.methods.totalSupply().call()

        this.setState({ totalSupply })

        console.log("total supply", this.props.totalSupply)

        console.log("comeon.................")


        /*
        const currentPrice = await contract.methods.getNFTPrice().call()
        this.setState({currentPrice})
        console.log("currentPrice",web3.utils.fromWei(currentPrice, 'ether'),"ETH" )*/

        /*fill up the owned items
         Check that some items have been minted already*/

        if (this.state.totalSupply > 0) {


            //getting the balance of the current owner
            const currentBalanceofNekos = await contract.methods.balanceOf(accounts[0]).call()
            console.log("currentBalanceofNekos", currentBalanceofNekos)

            if (currentBalanceofNekos > 0) {
                    for (var kk = 0; kk <= currentBalanceofNekos - 1; kk++){


                        //get them using the
                        const currentTokenID = await contract.methods.tokenOfOwnerByIndex(accounts[0],kk).call()
                        console.log("currentToken",kk, currentTokenID)


                        /*adding this ID to the state*/
                        this.setState({
                          tokenIdsOwned: [...this.state.tokenIdsOwned, currentTokenID]  // adds a new elemen tto an array
                        })





                        console.log("----fetching from API ----")
                        const tokenURI1 = await contract.methods.tokenURI(currentTokenID).call()
                        console.log("tokenURI", tokenURI1)


                        /*calling the api with AXIOS*/
                        axios.get(tokenURI1, {
                          headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Headers': 'Content-Type'
                          }
                        }).then(function (response) {

                          populateData2(response.data);

                        })
                          .catch(function (error) {


                            //console.log(error);
                            const dummyresponse = {"name":"Niftyriots","description":"Niftyriots: Dope Art created by Artificial Intelligence","image":"https://gateway.pinata.cloud/ipfs/QmbUn2Y8DU7UnywvKYCNeQVkWkWAv3kJET51mCST8vmDv8","internalname":"BBKHDCQRIJKPGBSR.jpg","externalname":"selv2_00030.jpg","tokenidbeforereveal":30,"tokenidafterreveal":null,"sha256":null,"version":"v2","external_url":null};
                            populateData2(dummyresponse);

                          });




                }

            }


        } /*end of MINTING amount > 0 */


        else {
          console.log("Supply is 0 for now")
        }


      } catch (e) {
        console.log('Error', e)
        window.alert('ERRORs -- Generic error in the web3')
      }

    } else {
      window.alert('Please install MetaMask')
    }
  }   // END OF LOADING block chain data



  render() {

    return (

      <OwnCollectionDetail
        S3_ADDRESS={this.props.S3_ADDRESS}
        tokensRevealed={this.props.tokensRevealed}
        arrayfromapi={this.state.arrayfromapi}
        account={this.state.account}
        contract={this.state.contract}
        arrayofrealids={this.state.tokenIdsOwned}

        namingStartTimestamp={this.props.namingStartTimestamp}
        handlerForms = {this.handlerForms}
        stateForms = {this.props.stateForms}

      />

    );
  }
}



const OwnCollectionDetail = (props) => {
  const [photoURL, setPhotoURL] = React.useState("");
  const [isOpenImage, setIsOpenImage] = React.useState(false);


  return (

    <div className="row justify-content-center pt-5 mb-5  pb-5">
      <ToastProvider autoDismiss autoDismissTimeout={10000}>
      {props.arrayfromapi.map((token, indexo) =>
        <div className="col-8 px-2 pb-3" key={token["tokenidbeforereveal"]}>
          {props.tokensRevealed == true ?

            <React.Fragment>
              <div className={( indexo == 0) ? "row justify-content-center orange-border orange-top" : "row justify-content-center  orange-border"} >
                <div className="col-4 px-1 pr-1" >
                    <div className="d-flex align-items-center justify-content-end">
                        <a href="#popup" onClick={e => { e.preventDefault(); setIsOpenImage(true); setPhotoURL(props.S3_ADDRESS + "artworks/" + token["internalname"]); }} >
                          <img src={props.S3_ADDRESS + "artworks/" + token["internalname"]} alt="image" className="img2 rounded" />
                        </a>
                    </div>
                </div>
                <div className="col-8 px-1 pl-1 pl-sm-1 pl-md-3 pl-lg-5 pl-xl-5" >
                  <div className="d-flex align-items-center justify-content-start">
                    <RenamingBoxes
                      realid={props.arrayofrealids[indexo]}
                      account={props.account}
                      contract={props.contract}

                      namingStartTimestamp={props.namingStartTimestamp}
                      stateForms={props.stateForms}
                      updateFormState={props.handlerForms}
                    />
                  </div>
                </div>
              </div>

            </React.Fragment>



            :


            <React.Fragment>
              <div className={( indexo == 0) ? "row justify-content-center orange-border orange-top" : "row justify-content-center  orange-border"} >
                <div className="col-4 px-1 pr-1" >
                    <div className="d-flex align-items-center justify-content-end">
                      <a href="#popup" onClick={e => { e.preventDefault(); setIsOpenImage(true); setPhotoURL(props.S3_ADDRESS + "webimages/" + "presel0.png"); }} >
                        <img src={props.S3_ADDRESS + "webimages/" + "presel0.png"} alt="image" className="img2 rounded" />
                      </a>
                    </div>
                </div>
                <div className="col-8 px-1 pl-1 pl-sm-1 pl-md-3 pl-lg-5 pl-xl-5" >
                  <div className="d-flex align-items-center justify-content-start">
                    <RenamingBoxes
                      realid={props.arrayofrealids[indexo]}
                      account={props.account}
                      contract={props.contract}

                      namingStartTimestamp={props.namingStartTimestamp}
                      stateForms={props.stateForms}
                      updateFormState={props.handlerForms}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>


          }


          {/* Lightbox */}
          {isOpenImage && (
            <Lightbox
              mainSrc={photoURL}
              onCloseRequest={() => setIsOpenImage(false)}
            />
          )}

        </div>
      )}
      </ToastProvider>
    </div>

  );

}




export default OwnCollectionv2;
