import React from 'react';
import ModalVideo from 'react-modal-video';

const MainBanner = () => {
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }
    return (
        <React.Fragment>
            {/* If you want to change the video need to update videoID */}
            <ModalVideo 
                channel='youtube' 
                isOpen={!isOpen} 
                videoId='bk7McNUjWgw' 
                onClose={() => setIsOpen(!isOpen)} 
            />

            <div className="main-banner">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <div className="main-banner-content">
            
                                    <h1 className="animate__fadeInLeft" style={{animationDuration: '1s'}} >Data Science Consulting Services</h1>
                                    <p className="animate__fadeInLeft" style={{animationDuration: '2s'}}>Get professional & reliable research oriented solutions for Data Science and Machine Learning business needs. Enjoy stress free decesion making!</p>
                                    <div className="btn-box animate__fadeInRight" style={{animationDuration: '5s'}}>
                                       
                                            <a href="/about-us" className="default-btn">
                                                <i className="flaticon-structure"></i> 
                                                Team <span></span>
                                            </a>
                                   

                                  
                                            <a href="#play-video"
                                                onClick={e => {e.preventDefault(); openModal()}}
                                                className="video-btn popup-youtube"
                                            > 
                                                <i className="flaticon-google-play"></i> Watch Video
                                            </a>
                                     
                                    </div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12">
                            <div className="main-banner-animation-image">
                            <img src= {require('main-banner/banner-one/gallery.png')} className="animate__fadeInDown" style={{animationDuration: '3s'}}  alt="image" />
                            <img src= {require('main-banner/banner-one/gallery.png')} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MainBanner;