import React from 'react';

const GenesisCollection = () => {
    return (
        <section className="what-we-do-area bg-fafafb pt-100 pb-150">

            <div className="container ">
                <div className="row justify-content-center">


                        <div className="col-md-10 col-md-push-1 col-sm-10 col-sm-push-1">

                            <div className="feature feature-1 service-1 text-center">

                                <h4>NiftyRiots : Genesis Collection</h4><hr />

                                <p align="left">

                                            Following long exchanges with the community and our collectors, we have decided to change the pricing structure of the NiftyRiots collection.
                                            Rather than having a price doubling every 2500 NFTs or so (from 0.10ETH all the way to 2.00ETH), we now have a flat pricing at 0.10ETH.
                                            This ensures a fair entry point for all collectors and appeases the community. While changing the price we have also reduced the number of NFTs available to only 2,500 from 11,111 previously.
                                            This will increase rarity of each NFT in the new NiftyRiots-Reloaded collection. To spoil our early collectors, they will receive x1.5 NFTs in the new collection rounded to the nearest integer.
                                            So if you bought 1 NFT before today (18th June 2021) in the old Legacy NiftyRiots Collection, you get 2 NFTs for free in the NiftyRiots-Reloaded collection.
                                            If you bought 10 NFTs before, you get 15 for free.

                                            The Legacy NiftyRiots v1 collection will be sunset.

                                            We are Lean and Mean...and we make Dope art  for you as always!


                                            <br></br>Join our Discord for any questions.
                                </p>

                                <hr />
                            </div>

                        </div>

                </div>
            </div>


        </section>
    )
}

export default GenesisCollection;
