import React,{useEffect,useRef} from "react"
//import ReactDom from 'react-dom'
import PropTypes from "prop-types"
import PageBanner from './Common/PageBanner';
import Lightbox from 'react-image-lightbox';
import NavbarTwoCustom from './_App/NavbarTwoCustom';  //   <NavbarTwoCustom /> //
import GoTop from './_App/GoTop';  //   <NavbarTwoCustom />
import FooterMin from './_App/FooterMin';
import {  RecoilRoot } from 'recoil'
import axios from "axios";


const ArtWorks = (props) => {
    const [photoIndex, setPhotoIndex] = React.useState(0);
    const [isOpenImage, setIsOpenImage] = React.useState(false);
    const [maxArtWorks, setMaxArtWorks ] = React.useState(props.maxArtworks);
    const [artWorksCount, setArtWorksCount, ] = React.useState(1);
    const [artWorksBatchSize, setArtWorksBatchSize, ] = React.useState(64);
    const [artWorksFirstLength, setArtWorksFirstLength, ] = React.useState(props.artWorksData.length);
    const [artWorksData, setArtWorksData ] = React.useState(props.artWorksData);
    const [artWorkFocus, setArtWorkFocus ] = React.useState(props.artWorksData[0].id);
    const myRefArtWork = useRef(null);

    const getArtWorkItems = async (batchsize) =>{
        try {
            const response = await axios.get("/artworks", { params: { batchsize: batchsize }});
            return response.data;
        } catch (err) {
            console.error(err);
        }
    }

    const getUniqueItems = (todoItems) =>{

        //const newStateData = artWorksData.concat(todoItems);

        let tempDic = {};
        todoItems.map(( data) => {
            tempDic[data.id]=data.internalname
        });

        let result = [];

        for (const [key, value] of Object.entries(tempDic)) {
            //console.log(key+"  : "+value)
            //result.push({"id":key, "internalname":value})

            if (!(artWorksData.find(obj => {
                //console.log("check id :"+obj.id)
                return parseInt(obj.id) == parseInt(key)
              })))
            {
                result.push({"id":parseInt(key), "internalname":value})
            }

        }

        return result;
    }

    const getTodoItems = async () => {
        if(artWorksData.length < maxArtWorks){
            setArtWorkFocus(artWorksData[artWorksData.length-1].id);

            const todoItems =  await getArtWorkItems(artWorksBatchSize);

            let result = artWorksData.concat(getUniqueItems(todoItems));

            const targetSize = artWorksFirstLength+(artWorksCount*artWorksBatchSize);

            while((result.length < targetSize) && (result.length < maxArtWorks)){
                console.log("Get More!!");
                let leftItems =  targetSize - result.length
                result = result.concat(getUniqueItems(await getArtWorkItems((leftItems < artWorksBatchSize)? leftItems :artWorksBatchSize)));
            }

            setArtWorksData([...result]);
            setArtWorksCount(artWorksCount+1);

        }
    }

    useEffect(() => {
        if(myRefArtWork){
            myRefArtWork.current.scrollIntoView()
        }

      });

  return (

      <React.Fragment>
          <RecoilRoot>
          <NavbarTwoCustom/>
          <GoTop />
          <PageBanner
              pageTitle="Gallery"
              homePageText="Home"
              homePageUrl="/"
              activePageText="Gallery"
          />

          <section className="gallery-area pt-100 pb-70">
              <div className="container">
                  <div className="row justify-content-center">
                          {artWorksData.map((artWork, index)  =>

                                <div className="col-lg-3 col-md-4 col-sm-4 "  key={artWork.id}>
                                    <div className="single-gallery-item">
                                            <a href="#popup"
                                                onClick={e => {e.preventDefault(); setIsOpenImage(true); setPhotoIndex(index);}}
                                            >
                                                { parseInt(artWork.id) == artWorkFocus?

                                                    <img src={props.S3_ADDRESS+"artworks/"+artWork.internalname } alt="image"
                                                    style={{ width: "auto", height: "380px"}}  ref={myRefArtWork}/>
                                                    :
                                                    <img src={props.S3_ADDRESS+"artworks/"+artWork.internalname } alt="image"
                                                    style={{ width: "auto", height: "380px"}}/>

                                                }

                                            </a>
                                    </div>
                                </div>

                          )}
                  </div>
              </div>

              {/* Lightbox */}
              {isOpenImage && (
                  <Lightbox
                      mainSrc={props.S3_ADDRESS+"artworks/"+artWorksData[photoIndex].internalname }
                      nextSrc={props.S3_ADDRESS+"artworks/"+artWorksData[(photoIndex + 1) % artWorksData.length].internalname }
                      prevSrc={props.S3_ADDRESS+"artworks/"+artWorksData[(photoIndex + artWorksData.length - 1) % artWorksData.length].internalname }
                      onCloseRequest={() => setIsOpenImage(false)}
                      onMovePrevRequest={() =>
                          setPhotoIndex((photoIndex + artWorksData.length - 1) % artWorksData.length)
                      }
                      onMoveNextRequest={() =>
                          setPhotoIndex((photoIndex + 1) % artWorksData.length)
                      }
                  />
              )}

        <div className="container">
                    <div className="row justify-content-center">
                        <button type="button"  onClick={getTodoItems} className="default-btn-custom">Load more</button>
                    </div>
                </div>
          </section>

          <FooterMin />
          </RecoilRoot>
      </React.Fragment>
  );
}


ArtWorks.propTypes = {
  artWorksData:  PropTypes.array,
  artWorksIDs:  PropTypes.array,
  maxArtworks: PropTypes.number,
  S3_ADDRESS:  PropTypes.string
};

export default ArtWorks
