import React from 'react';
import { useRecoilState, RecoilRoot } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'

import logoImage from 'logo.png'



const NavbarTwo = () => {

    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    })
    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
      
                <div id="navbar" className="navbar-area navbar-style-two">
                <div className="tarn-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            
                            <a href="/" onClick={() => setCollapsed(true)} className="navbar-brand">
                                <img src={logoImage} alt="logo" />
                            </a>
                        

                            <button 
                                onClick={toggleNavbar} 
                                className={classTwo}
                                type="button" 
                                data-toggle="collapse" 
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                                aria-expanded="false" 
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                  
                                            <a  href="#" onClick={e => e.preventDefault()} className="nav-link">
                                                Home <i className='bx bx-chevron-down'></i>
                                            </a>
                                        

                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                  
                                                    <a href="/" onClick={() => setCollapsed(true)} className="nav-link">IT Services</a>
                                          
                                            </li>

                                       
                                        </ul>
                                    </li>

                                </ul>
                            
                                <div className="others-option d-flex align-items-center">
                                    <div className="option-item">
                                            <a href="/contact" onClick={() => setCollapsed(true)} className="default-btn">
                                                <i className="flaticon-right"></i> Get Started <span></span>
                                            </a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
           
        </React.Fragment>
    );
}

export default NavbarTwo;
