import React from 'react';

const Map = (props) => {
    return (
        <section className="what-we-do-area back-ground-gray pt-5 pb-5">

            <div className="container ">
                <div className="row justify-content-center">


                    <div className="col-md-10 col-md-push-1 col-sm-10 col-sm-push-1">
                        <div className="feature feature-1 service-1 text-left">
                            <h4 className="color-FF13A7" >Find us there:</h4>
                            <div className="red-underline mt-3 mb-4"></div>
                        </div>
                    </div>
                    <div className="col-md-9 col-sm-9 ">

                        <div className="feature feature-1 service-1 text-center">

                            <p className="color-FFFFFF" align="left">66We feel incredibly thrilled to be working in a dynamic part of the word, namely South East Asia. The NiftyRiots projects is brought to you by ReDNA Labs, Co. Ltd - A company based in the Chonburi province of Thailand.
                                If you are nearby come over for a coffee - we will discuss art, web3 and of course NFTs.


                            </p>

                            <p>Write to us: ai@rednalabs.com</p>

                            <hr />
                        </div>

                    </div>


                </div>

                <div className="row justify-content-center pt-1 pb-5">

                <div className="col-lg-8 col-md-8 pb-8 "  >
                        <div className="d-flex flex-column ">
                            <div className="d-flex justify-content-center ">
                                <div className="image " style={{ maxWidth: "80%" }}>


                                    <img src={"https://mkokeshi.s3.ap-southeast-1.amazonaws.com/webimages/map_redna.png"} />


                                </div>
                            </div>

                            <div className="d-flex justify-content-center pt-4"><h5 className="color-FF13A7"></h5> </div>

                        </div>
                    </div>



                </div>
            </div>



        </section>
    )
}

export default Map;
