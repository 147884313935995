import React from 'react';
import dateFormat from 'dateformat';
import moment2 from "moment-timezone";
import moment from "moment";


class ProjectControlBit extends React.Component {

    constructor(props) {
        super(props);

        if  (    ((this.props.projectControl !== undefined) && (this.props.projectControl != null))   ) {

            this.state = {
        //        salesIsOn: this.props.projectControl.salesison,
        //        preSaleIsOn: this.props.projectControl.presaleison,

                checkPulseIsOn: this.props.projectControl.checkpulse,
                mecheck: true

            };


    //        console.log("checkPulseIsOn:",this.state.checkPulseIsOn);
    //        console.log("mecheck:",this.state.mecheck);

    //        var kol =  moment.tz("2022-12-20T20:00:41.445Z", "Asia/bangkok");
    //        console.log(kol.format());




        }else{

            this.state = {
        //        salesIsOn: false,
        //        preSaleIsOn: false,
                pulseIsOn: false,
            };


            console.log("checkPulseIsOn not found");


        }

    //    this.handleOnSale = this.handleOnSale.bind(this)
    //    this.handleOnPreSale = this.handleOnPreSale.bind(this)
        this.handleCheckPulse = this.handleCheckPulse.bind(this)



    }





    handleCheckPulse(){
        this.setState({checkPulseIsOn: !this.state.checkPulseIsOn})
    }



    render() {



        return (
            <section className="what-we-do-area bg-fafafb pt-0 pb-5">

                <div className="container ">
                    <div className="row">
                        { !((this.props.projectControl === undefined )|| (this.props.projectControl == null)) ?

                            <div className="col-sm-12">

                                <div className="col-md-10 col-md-push-1 col-sm-10 col-sm-push-1">


                                    <div className="feature feature-1 service-1 text-center">

                                    Checking Pulse? <h1>{this.props.projectControl["checkpulse"] == false ? "FALSE" : "TRUE"}</h1>

                                    </div>


                                    <div className="feature feature-1 service-1 text-center">


                                    Last Incoming (BKK time) : { moment(this.props.projectControl["latestincomingsignal"]).format('MMMM Do YYYY, HH:mm:ss') } ({moment(this.props.projectControl["latestincomingsignal"]).fromNow()})
                                    </div>



                                    <div className="feature feature-1 service-1 text-center">


                                    Latest Pulse (BKK time) : { moment(this.props.projectControl["latestpulsecheck"]).format('MMMM Do YYYY, HH:mm:ss') } ({moment(this.props.projectControl["latestpulsecheck"]).fromNow()})

                                    </div>



                                    <div className="feature feature-1 service-1 text-center">

                                        <form action="/dashboardbitupdateproject" method="post" onSubmit={(e) => {
                                                    }}>

                                            <input
                                                type='hidden'
                                                name="authenticity_token"
                                                value={this.props.authenticity_token}
                                                ref={(input) => { this.authenticity_token = input }}
                                            />


                                            <input
                                                type='hidden'
                                                name="project[redirect]"
                                                value={true}
                                            />





                                            <div className="form-check form-check-inline mb-3  mx-5">

                                                <input
                                                    type='hidden'
                                                    name="project[checkpulse]"
                                                    value={0}
                                                />

                                                <input
                                                    id="project_checkpulse"
                                                    type='checkbox'
                                                    className="form-check-input mb-1"
                                                    name="project[checkpulse]"
                                                    value={1}
                                                    ref={(input) => { this.checkPulseIsOn = input }}
                                                />
                                                <label className="form-check-label" htmlFor="project_checkpulse">Checking Pulse</label>
                                            </div>



                                            <div className="form-group ">
                                                <input type='submit' className='btn btn-block btn-primary' value='Activate/Deactivate Pulse' />
                                            </div>




                                        </form>

                                        <hr />
                                    </div>

                                </div>

                            </div>


                            :

                            <div className="col-sm-12">

                                hello

                            </div>



                        }
                    </div>
                </div>


            </section>
        )
    }
}

export default ProjectControlBit;
