import React from 'react';

const Animated = (props) => {
    return (
        <section className="what-we-do-area back-ground-gray pt-5 pb-5">

            <div className="container ">
                <div className="row justify-content-center">


                    <div className="col-md-10 col-md-push-1 col-sm-10 col-sm-push-1">
                        <div className="feature feature-1 service-1 text-left">
                            <h4 className="color-FF13A7" >ANIMATED AI COLLECTION</h4>
                            <div className="red-underline mt-3 mb-4"></div>
                        </div>
                    </div>

                    <div className="col-md-9 col-sm-9 ">

                        <div className="feature feature-1 service-1 text-center">

                            <p className="color-FFFFFF" align="left">To capitalize on the world class AI research talent in the NiftyRiots team, we are planning to soon release a new drop of NFT animated and created with AI. The team has managed to devise a method which overcomes the limitations of current approaches and we will
                            release a large scale collection of NFT using that approach. This is literally cutting edge tech. Below is a sneak peak as to what it will look like.
                            <br></br>Discord: <a className="color-FF13A7" href="https://discord.gg/6gWXvGF4gr"><b>NIFTYRIOTS</b></a>
                            </p>

                            <hr />
                        </div>

                    </div>


                </div>

                <div className="row justify-content-center pt-1 pb-5">



                    <div className="col-lg-3 col-md-3 pb-5 "  >
                        <div className="d-flex flex-column ">
                            <div className="d-flex justify-content-center ">
                                <div className="image " style={{ maxWidth: "80%" }}>

                                </div>
                            </div>

                            <div className="d-flex justify-content-center pt-4"><h5 className="color-FF13A7"></h5> </div>

                        </div>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default Animated;
