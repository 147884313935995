import React from "react"
import PropTypes from "prop-types"

import {  RecoilRoot } from 'recoil'

import NavbarTwoCustom from './_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
import Solutions from './MachineLearningAISolutions/Solutions';
import ContractNFT from './MachineLearningAISolutions/ContractNFT';
import RecentProjects from './MachineLearningAISolutions/RecentProjects';
import FAQ from './Common/FAQ'
import Footer from './_App/Footer';
import GoTop from './_App/GoTop';  //   <NavbarTwoCustom />
import Web3 from 'web3';
import niftyriots from '../contracts/Niftyriots.json'

class RootPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPrice: 0.1,
    };

  }

  //mounting the main Element
  async componentDidMount() {
    await this.loadWeb3_currentPrice(this.props.dispatch)

  }


  //network and web3 logic

  async loadWeb3_currentPrice(dispatch) {
    console.log("loadWeb3")
    if (window.ethereum) {
      console.log("Starting up....")
      const web3 = new Web3(window.ethereum)    // METAMASK CONNECTION
      const netId = await web3.eth.net.getId()     // gets the value of the network
      const enabledWeb3 = await ethereum.enable();
      const accounts = await web3.eth.getAccounts()

      //load balance
      if (typeof accounts[0] !== 'undefined') {
        console.log("-----getting balance-----")
        const balance = await web3.eth.getBalance(accounts[0])
        console.log("-----balance-----")
        console.log(balance)
        console.log("balance", web3.utils.fromWei(balance, 'ether'), "ETH")

        this.setState({ account: accounts[0], balance: balance, web3: web3 })

      } else {
        //window.alert('Please login with MetaMask')

      }

      //load contracts
      try {

        console.log("niftyriots.networks[netId].address  ->",niftyriots.networks[netId].address)

        const contract = new web3.eth.Contract(niftyriots.abi, niftyriots.networks[netId].address)  // comes from ABI json file
        //get the current price

        const currentPrice_wei = await contract.methods.NiftyPrice().call().catch(error => {
          return web3.utils.toWei('0.1', 'ether')
        });

        this.setState({ currentPrice: web3.utils.fromWei(currentPrice_wei, 'ether') })
        console.log("currentPrice", web3.utils.fromWei(currentPrice_wei, 'ether'), "ETH")

      } catch (e) {
        console.log('Error', e)
        window.alert('ERRORs -- Generic error in the web3')
      }

    } else {
      window.alert('Please install MetaMask')
    }
  }   // END OF LOADING block chain data


  //run it in a loop
  // componentDidMount() {
  //   this.timerID = setInterval(
  //           () => this.weinit(),
  //                10000    );
  //              }


  render () {

        return (

          <React.Fragment>
          <RecoilRoot>
          <NavbarTwoCustom/>
          <Solutions artWorksData={this.props.artWorksData} S3_ADDRESS={this.props.S3_ADDRESS}  SALE_START_TIMESTAMP={this.props.SALE_START_TIMESTAMP} currentPrice={this.state.currentPrice} TOTAL_ITEMS={this.props.TOTAL_ITEMS} UNIQUE_PRICE={this.props.UNIQUE_PRICE} PRESALESTART={this.props.PRESALESTART}  salesIsOn={this.props.salesIsOn}/>
          <RecentProjects IGID={this.props.IGID} IGTOKEN={this.props.IGTOKEN} S3_ADDRESS={this.props.S3_ADDRESS}/>
          <FAQ />
          <ContractNFT S3_ADDRESS={this.props.S3_ADDRESS} currentPrice={this.state.currentPrice} TOTAL_ITEMS={this.props.TOTAL_ITEMS} UNIQUE_PRICE={this.props.UNIQUE_PRICE} PRESALESTART={this.props.PRESALESTART}  salesIsOn={this.props.salesIsOn} />
          <Footer S3_ADDRESS={this.props.S3_ADDRESS} user_signed_in={this.props.user_signed_in} />
          <GoTop />
          </RecoilRoot>
        </React.Fragment>

        )

        /*
        return (<div>Coming up</div>)
        */

  }



}




RootPage.propTypes = {
  artWorksData: PropTypes.array,
  S3_ADDRESS: PropTypes.string
};

export default RootPage
