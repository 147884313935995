// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
//  old import nekojs from './contracts/Nekoxs.json'

import niftyriots from './../contracts/Niftyriots.json'


import axios from 'axios';



import { RecoilRoot } from 'recoil'
import { ToastProvider } from 'react-toast-notifications';

import NavbarTwoCustom from './../components/_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
import GoTop from './../components/_App/GoTop';  //   <NavbarTwoCustom />
import Transactions from './../components/Common/Transactions';
import FooterMin from './../components/_App/FooterMin';



const LoaddingW3 = (props) => {
  return (
    <div><p>Loadding...</p></div>
  )
}

//////////////////////////////////////////////////////////
///changed to page interacting with the smart contract
//////////////////////////////////////////////////////////

class TransactionPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

      account: '',
      contract: null,
      totalSupply: 0,
      sizeToBuy: 0,
      currentPrice: props.currentPrice,
      tokenIdsOwned: [],
      tokenIdsOwnedNames: [],
      nftowner: '',
      nftbal: 0,
      date: new Date(),
      tokenURI: "",
      hideBuySellBox: false,

    };
    this.updateAccount=this.updateAccount.bind(this);
  }




  //mounting the main Element
  async componentDidMount() {
    await this.loadWeb3(this.props.dispatch)

  }

  //Renaming function
  async werename(tokenId, newName) {

    if (this.state.contract !== 'undefined') {
      try {

        await this.state.contract.methods.changeName(tokenId, newName).send({ from: this.state.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }

  updateAccount(account_current){

    this.setState({account: account_current})
  }



  //network and web3 logic

  async loadWeb3(dispatch) {
    console.log("loadWeb3")
    if (window.ethereum) {

      console.log("Starting up....")
      const web3 = new Web3(window.ethereum)    // METAMASK CONNECTION
      const netId = await web3.eth.net.getId()     // gets the value of the network
      const enabledWeb3 = await ethereum.enable();
      const accounts = await web3.eth.getAccounts()

      //load balance
      if (typeof accounts[0] !== 'undefined') {
        console.log("-----getting balance-----")
        const balance = await web3.eth.getBalance(accounts[0])
        console.log("-----balance-----")
        console.log(balance)
        console.log("balance", web3.utils.fromWei(balance, 'ether'), "ETH")

        this.setState({ account: accounts[0], balance: balance, web3: web3})

      } else {
        //window.alert('Please login with MetaMask')
      }


      //load contracts
      try {


        console.log("niftyriots.networks[netId].address  ->",niftyriots.networks[netId].address)

        const contract = new web3.eth.Contract(niftyriots.abi, niftyriots.networks[netId].address)  // comes from ABI json file


        const mname = await contract.methods.name().call();
        const msymbol = await contract.methods.symbol().call();

        console.log("contract name  ->",mname,msymbol)

        this.setState({ contract }) /// shortcut for {contract : contract}

        const totalSupply = await contract.methods.totalSupply().call()

        this.setState({ totalSupply })

        console.log("total supply", this.props.totalSupply)


        //get the current price
        const currentPrice = await contract.methods.NiftyPrice().call().catch(error => {
          return web3.utils.toWei('1000', 'ether')
        });

        this.setState({ currentPrice })
        console.log("currentPrice", web3.utils.fromWei(currentPrice, 'ether'), "ETH")

        //fill up the owned items

        if (this.state.totalSupply > 0) {

          //getting the tokenURI
          const tokenURI = await contract.methods.tokenURI(0).call()

          console.log("Large supply now", this.state.totalSupply)


          console.log("tokenURI", tokenURI)


          const data = axios.get(tokenURI, {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': 'Content-Type',
            }
          }).then(function (response) {
            console.log('response is : ' + response.data);
          }).catch(function (error) {
            if (error.response) {
              console.log(error.response.headers);
            }
            else if (error.request) {
              console.log(error.request);
            }
            else {
              console.log(error.message);
            }
            console.log(error.config);
          });

          console.log("data fetched", data);




          for (var i = 0; i <= Math.min(totalSupply - 1, 5); i++) {
            const mownerOf = await contract.methods.ownerOf(i).call()

            if (mownerOf === accounts[0]) {

              this.setState({
                tokenIdsOwned: [...this.state.tokenIdsOwned, i]  // adds a new elemen tto an array
              })

            }

            console.log("tokenIdsOwned", this.state.tokenIdsOwned)
          }

        }
        else {
          console.log("Supply is 0 for now")
        }


      } catch (e) {
        console.log('Error', e)
        window.alert('ERRORs -- Generic error in the web3')
      }

    } else {
      window.alert('Please install MetaMask')
    }
  }   // END OF LOADING block chain data


  //run it in a loop
  // componentDidMount() {
  //   this.timerID = setInterval(
  //           () => this.weinit(),
  //                10000    );
  //              }



  render() {


    return (
      <React.Fragment>
        <RecoilRoot>
          <NavbarTwoCustom />
          <ToastProvider autoDismiss autoDismissTimeout={10000}>
            <Transactions hideBuySellBox={this.state.hideBuySellBox} currentPrice={this.state.currentPrice} contract={this.state.contract} account={this.state.account}  salesison={this.props.salesison} updateAccount={this.updateAccount} dispatch={this.props.dispatch} />
          </ToastProvider>
          <GoTop />
          <FooterMin fixedBottom={true} />
        </RecoilRoot>
      </React.Fragment>
    );
  }
}

export default TransactionPage