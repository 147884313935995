// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';

//old version reads : import nekojs from './contracts/Nekoxs.json'

import niftyriots from './../contracts/Niftyriots.json'
import axios from 'axios';



import { RecoilRoot } from 'recoil'

import NavbarTwoCustom from './../components/_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
import GoTop from './../components/_App/GoTop';  //   <NavbarTwoCustom />
import PageBanner from './../components/Common/PageBanner';
import Withdrawals from './../components/Common/Withdrawals';
import Migrationsv1tov2 from './../components/Common/Migrationsv1tov2';
import ProjectControlBit from './../components/Common/ProjectControlBit';
//import FAQ from './components/Common/FAQ';
//import Disclaimer from './components/Common/Disclaimer';
import FooterMin from './../components/_App/FooterMin';

//////////////////////////////////////////////////////////
///changed to page interacting with the smart contract
//////////////////////////////////////////////////////////

class DashBoardPage extends React.Component {



  //mounting the main Element
  async componentDidMount() {
    await this.loadWeb3(this.props.dispatch)

  }

  //Renaming function
  async werename(tokenId, newName) {

    if (this.state.contract !== 'undefined') {
      try {

        await this.state.contract.methods.changeName(tokenId, newName).send({ from: this.state.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }

  //network and web3 logic

  async loadWeb3(dispatch) {
    if (window.ethereum) {



      const web3 = new Web3(window.ethereum)    // METAMASK CONNECTION
      const netId = await web3.eth.net.getId()      // gets the value of the network
      const enabledWeb3 = await ethereum.enable();
      console.log("netId", netId)
      const accounts = await web3.eth.getAccounts()
      console.log("accounts[0]", accounts[0])



      //load balance//
      if (typeof accounts[0] !== 'undefined') {
        console.log("-----getting balance-----")
        const balance = await web3.eth.getBalance(accounts[0])
        console.log("-----balance-----")
        console.log(balance)
        console.log("balance", web3.utils.fromWei(balance, 'ether'), "ETH")

        this.setState({ account: accounts[0], balance: balance, web3: web3 })

      } else {
       // window.alert('Please login with MetaMask')
      }


      //load contracts
      try {


        const contract = new web3.eth.Contract(niftyriots.abi, niftyriots.networks[netId].address)  // comes from ABI json file

        this.setState({ contract }) /// shortcut for {contract : contract}

        const totalSupply = await contract.methods.totalSupply().call()

        this.setState({ totalSupply })

        console.log("contract address", niftyriots.networks[netId].address)

        const contractBalance = await web3.eth.getBalance(niftyriots.networks[netId].address, function (err, result) {
          if (err) {
            console.log(err)
          } else {


            console.log("Balance of Contract:", web3.utils.fromWei(result, "ether") + " ETH")
          }
        })
        console.log("contract balance", contractBalance)

        this.setState({ contractBalance })


        const saleOk = await contract.methods.SaleOk().call()

        console.log("SaleOk", saleOk)

        this.setState({ saleOk })



        console.log("total supply", this.props.totalSupply)

        console.log("getting the ownerAccount")

        //get the ownerAccount
        const owner = await contract.methods.owner().call()
        this.setState({ ownerAccount: owner })
        console.log("owner", owner)


        //get the current price
        //      const currentPrice = await contract.methods.getNFTPrice().call()
        //      this.setState({currentPrice})
        //      console.log("currentPrice",web3.utils.fromWei(currentPrice, 'ether'),"ETH" )


        //gets the startingIndex
        //          const startingIndex = await contract.methods.startingIndex().call()
        //          this.setState({startingIndex})
        //          console.log("startingIndex",this.state.startingIndex)


        //          //gets the startingIndexBlock
        //          const startingIndexBlock = await contract.methods.startingIndexBlock().call()
        //          this.setState({startingIndexBlock})
        //          console.log("startingIndexBlock",this.state.startingIndexBlock)


        //fill up the owned items

        if (this.state.totalSupply > 0) {

          //getting the tokenURI
          const tokenURI = await contract.methods.tokenURI(0).call()

          console.log("Large supply now", this.state.totalSupply)


          console.log("tokenURI", tokenURI)


          const data = axios.get(tokenURI, {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': 'Content-Type',
            }
          }).then(function (response) {
            console.log('response is : ' + response.data);
          }).catch(function (error) {
            if (error.response) {
              console.log(error.response.headers);
            }
            else if (error.request) {
              console.log(error.request);
            }
            else {
              console.log(error.message);
            }
            console.log(error.config);
          });

          console.log("data fetched", data);




          for (var i = 0; i <= Math.min(totalSupply - 1, 5); i++) {
            const mownerOf = await contract.methods.ownerOf(i).call()

            if (mownerOf === accounts[0]) {

              this.setState({
                tokenIdsOwned: [...this.state.tokenIdsOwned, i]  // adds a new elemen tto an array
              })

            }

            console.log("tokenIdsOwned -- NOT IN USE, JUST FIRST 5!!!- using min function", this.state.tokenIdsOwned)
          }

        }
        else {
          console.log("Supply is 0 for now")
        }


      } catch (e) {
        console.log('Error', e)
        window.alert('ERRORs -- Generic error in the web3')
      }

    } else {
      window.alert('Please install MetaMask')
    }
  }   // END OF LOADING block chain data


  constructor(props) {
    super(props);
    this.state = {
      account: '',
      contract: null,
      ownerAccount: null,
      totalSupply: 0,
      sizeToBuy: 0,
      currentPrice: 10,
      tokenIdsOwned: [],
      tokenIdsOwnedNames: [],
      nftowner: '',
      nftbal: 0,
      contractBalance: 0,
      date: new Date(),
      tokenURI: "",
      startingIndex: null,
      startingIndexBlock: null,
      saleOk: false

    };

  }



  //run it in a loop
  // componentDidMount() {
  //   this.timerID = setInterval(
  //           () => this.weinit(),
  //                10000    );
  //              }



  render() {
    return (
      <React.Fragment>
        <RecoilRoot>
          <NavbarTwoCustom />


          <PageBanner
            pageTitle="DashboardBit"
            homePageText="Home"
            homePageUrl="/"
            activePageText="DashboardBit"
          />



          <ProjectControlBit
            projectControl = {this.props.projectControl}
            authenticity_token = {this.props.authenticity_token}
          />

          <PageBanner
            pageTitle=""
            homePageText=""
            homePageUrl=""
            activePageText=""
          />


          <FooterMin fixedBottom={true} />
          <GoTop />
        </RecoilRoot>
      </React.Fragment>
    );
  }
}
export default DashBoardPage
