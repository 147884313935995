import React from 'react';

const Team = (props) => {
    return (
        <section className="what-we-do-area back-ground-gray pt-5 pb-5">

            <div className="container ">
                <div className="row justify-content-center">


                    <div className="col-md-10 col-md-push-1 col-sm-10 col-sm-push-1">
                        <div className="feature feature-1 service-1 text-left">
                            <h4 className="color-FF13A7" >The Crew</h4>
                            <div className="red-underline mt-3 mb-4"></div>
                        </div>
                    </div>
                    <div className="col-md-9 col-sm-9 ">

                        <div className="feature feature-1 service-1 text-center">

                            <p className="color-FFFFFF" align="left">This incredible project is from NiftyRiots. The lab was started by GloriaMundi, ChiefOps and OneShin to develop and create nice projects.
                            This project is a way for us to show you our generative arts on NFTs. GloriaMundi, the keyman who brings the cool feature. ChiefOps, who develops the website and minitool. OneShin, who makes everything smooth. We love to chat just about anything. So feel free to hit us up!
                            <br></br>Discord: <a className="color-FF13A7" href="https://discord.gg/6gWXvGF4gr"><b>NIFTYRIOTS</b></a>
                            </p>

                            <hr />
                        </div>

                    </div>


                </div>

                <div className="row justify-content-center pt-1 pb-5">

                <div className="col-lg-3 col-md-3 pb-5 "  >
                        <div className="d-flex flex-column ">
                            <div className="d-flex justify-content-center ">
                                <div className="image " style={{ maxWidth: "80%" }}>
                                    <img src={props.S3_ADDRESS + "webimages/" + "newkokeshi228_QQQQ_tors_131416750.jpg_QQQQ_groupa_14.jpg_QQQQ_.png"} />
                                </div>
                            </div>

                            <div className="d-flex justify-content-center pt-4"><h5 className="color-FF13A7">GloriaMundi</h5> </div>

                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 pb-5 "  >
                        <div className="d-flex flex-column ">
                            <div className="d-flex justify-content-center ">
                                <div className="image " style={{ maxWidth: "80%" }}>
                                    <img src={props.S3_ADDRESS + "webimages/" + "newkokeshi197_QQQQ_mika_12152.jpg_QQQQ_groupa_14.jpg_QQQQ_.png"} />
                                </div>
                            </div>

                            <div className="d-flex justify-content-center pt-4"><h5 className="color-FF13A7">ChiefOps</h5> </div>

                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 pb-5 "  >
                        <div className="d-flex flex-column ">
                            <div className="d-flex justify-content-center ">
                                <div className="image " style={{ maxWidth: "80%" }}>
                                    <img src={props.S3_ADDRESS + "webimages/" + "newkokeshi135_QQQQ_banditsni_264801_own.jpg_QQQQ_groupa_14.jpg_QQQQ_.png"} />
                                </div>
                            </div>

                            <div className="d-flex justify-content-center pt-4"><h5 className="color-FF13A7">OneShin</h5> </div>

                        </div>
                    </div>
                </div>
            </div>



        </section>
    )
}

export default Team;