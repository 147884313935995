import React from 'react';

const Footer = (props) => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-7">
                        <div className="single-footer-widget">

                            <a href="/" className="logo">
                                <img src={props.S3_ADDRESS + "webimages/logov2.png"} alt="logo" />
                            </a>

                            <div className="d-flex justify-content-center">
                                <ul className="social-link">
                                    <li>

                                        <a href="https://twitter.com/niftyriots" className="d-block" target="_blank">
                                            <i className='bx bxl-twitter'></i>
                                        </a>

                                    </li>
                                    <li>

                                        <a href="https://www.instagram.com/nifty_riots/" className="d-block" target="_blank">
                                            <i className='bx bxl-instagram'></i>
                                        </a>

                                    </li>
                                    <li>

                                        <a href="https://discord.gg/6gWXvGF4gr" className="d-block" target="_blank">
                                            <i className='bx bxl-discord'></i>
                                        </a>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-sm-5">
                        <div className="footer-bottom-area-custom font-Nunito" >
                            <div className="d-flex justify-content-end pt-3">
                                <h3>Explore</h3>
                            </div>
                            <ul className="footer-links-list-5 pb-3">
                                <li>

                                    <a href="/team" className="font-Nunito"> <b>Team</b></a>

                                </li>


                                <li>

                                    <a href="/terms_of_service" className="font-Nunito"> <b>Terms & Conditions</b></a>

                                </li>
                                <li>

                                    <a href="/disclaimer" className="font-Nunito"> <b>Disclaimer</b></a>

                                </li>
                                <li>

                                    <a href="/how_to_buy" className="font-Nunito"> <b>How to Buy</b></a>

                                </li>
                                <li>

                                    <a href="/users/sign_in" className="font-Nunito"> <b>Admin</b></a>

                                </li>
                                {props.user_signed_in ?

                                    <li >
                                        <a href="/dashboardbit" className="font-Nunito"><b>Dashboard Bit</b></a>
                                    </li>
                                    :
                                    <div/>
                                }


                            </ul>

                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="footer-bottom-area font-Nunito" style={{ marginTop: "0px" }}>
                            <div className="d-flex justify-content-end justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end   pt-50">
                                <p>Copyright &copy;{currentYear} <strong><a href="/map" style={{ "fontSize": "16px", "color": "black" }}>NiftyRiots</a></strong> All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    );
}

export default Footer;
