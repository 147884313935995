import React from "react"
import PropTypes from "prop-types"
import { RecoilRoot } from 'recoil'
import NavbarTwoCustom from './_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
//import Footer from './_App/Footer';
import FooterMin from './_App/FooterMin';
import GoTop from './_App/GoTop';  //   <NavbarTwoCustom />
import Web3 from 'web3';
import niftyriots from '../contracts/Niftyriots.json'

import axios from 'axios';

class PreSaleIndexPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPrice: 0.1,
      data: [],
      sum: 0,
      sumentries: 0,
      sumwhitelistedentries:0,
      sumwhitelistedentriesaccepted:0

    };
    console.log("**********  authenticity_token *************")
    console.log(props.authenticity_token)
  }

  //mounting the main Element
  async componentDidMount() {
    await this.loadWeb3(this.props.dispatch)

    const res = await axios.get("/presales", {
      headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type'
      }
    });

    let data = res.data;
    this.setState({ data: data })
    let tmpsum = 0
    this.state.data.map((token, indexo) => { tmpsum = tmpsum + parseInt(token["amount"]) })
    this.setState({ sum: tmpsum })

    let tmpsumentries = 0
    this.state.data.map((token, indexo) => { tmpsumentries = tmpsumentries + 1 })
    this.setState({ sumentries: tmpsumentries })

    console.log(data);
    let tmpsumwhitelistedentries = 0
    this.state.data.map((token, indexo) => { token["whitelisted"].toString() ==="true" ?   tmpsumwhitelistedentries = tmpsumwhitelistedentries + 1 : 11 })
    this.setState({ sumwhitelistedentries: tmpsumwhitelistedentries })

    console.log(data);
    let tmpsumwhitelistedentriesaccepted = 0
    this.state.data.map((token, indexo) => { token["whitelisted"].toString() ==="true" ?   tmpsumwhitelistedentriesaccepted = tmpsumwhitelistedentriesaccepted + parseInt(token["amount"]) : 11 })
    this.setState({ sumwhitelistedentriesaccepted: tmpsumwhitelistedentriesaccepted })



  }


  //network and web3 logic

  async loadWeb3(dispatch) {
    console.log("loadWeb3")
    if (window.ethereum) {
      console.log("Starting up....")
      const web3 = new Web3(window.ethereum)    // METAMASK CONNECTION
      const netId = await web3.eth.net.getId()     // gets the value of the network
      const enabledWeb3 = await ethereum.enable();
      const accounts = await web3.eth.getAccounts()

      //load balance
      if (typeof accounts[0] !== 'undefined') {
        console.log("-----getting balance-----")
//        const balance = await web3.eth.getBalance(accounts[0])
//        console.log("-----balance-----")
//        console.log(balance)
//        console.log("balance", web3.utils.fromWei(balance, 'ether'), "ETH")

//        this.setState({ account: accounts[0], balance: balance, web3: web3 })

      } else {
        //window.alert('Please login with MetaMask')

      }

      //load contracts
      try { 1

//        console.log("niftyriots.networks[netId].address  ->", niftyriots.networks[netId].address)

//        const contract = new web3.eth.Contract(niftyriots.abi, niftyriots.networks[netId].address)  // comes from ABI json file
        //get the current price

//        const currentPrice_wei = await contract.methods.NiftyPrice().call().catch(error => {
//          return web3.utils.toWei('0.1', 'ether')
//        });

//        this.setState({ currentPrice: web3.utils.fromWei(currentPrice_wei, 'ether') })
//        console.log("currentPrice", web3.utils.fromWei(currentPrice_wei, 'ether'), "ETH")

      } catch (e) {
        console.log('Error', e)
        window.alert('ERRORs -- Generic error in the web3')
      }

    } else {
      window.alert('Please install MetaMask')
    }
  }   // END OF LOADING block chain data
  //<td><a href={"/presales/" + token["id"] + "/edit"}>Edit</a></td>



  render() {

    return (
      <React.Fragment>
        <RecoilRoot>
          <NavbarTwoCustom />

          <section className="what-we-do-area bg-fafafb pt-100 pb-100">

            <div className="container ">

              <div className="row justify-content-center">
                <div className="col-12">
                  <table className="table">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col" className="text-left" >ETH Address</th>
                        <th scope="col" className="text-center" >Amount</th>
                        <th scope="col" className="text-center" >Send Status / Whitelisted (true/false)</th>
                        <th scope="col" className="text-center" ></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody className="tbody-dark" align="center">
                      {this.state.data.map((token, indexo) =>

                        <tr key={indexo}>

                          <td>{token["ethaddress"]}</td>
                          <td>{token["amount"]}</td>



                          <td>

                          <form id={token["ethaddress"]+"-"+token["id"]} action={"/presales/" + token["id"]} method="post" onSubmit={(e) => {

                            console.log("Submit")
                          }}>

                              <input
                                type='hidden'
                                name="authenticity_token"
                                value={this.props.authenticity_token}
                                ref={(input) => { this.authenticity_token = input }}

                              />
                              <input
                                type='hidden'
                                name="_method"
                                value="patch"
                                ref={(input) => { this._method = input }}
                              />

                              <input
                                type='hidden'
                                name="presale[redirect]"
                                value="true"
                                ref={(input) => { this._method = input }}
                              />


                              <input
                                id="presale-ethaddress"
                                type='text'
                                className="form-control text-center"
                                style={{"height":"25px"}}
                                name="presale[status]"
                                defaultValue={token["status"]?token["status"] :""}
                                ref={(input) => { this.status = input }}
                              />


                              <input
                                id="presale-whitelisted"
                                type='text'
                                className="form-control text-center"
                                style={{"height":"25px"}}
                                name="presale[whitelisted]"
                                defaultValue={token["whitelisted"] }
                                ref={(input) => { this.whitelisted = input }}
                              />




                          </form>
                          </td>






                          <td> <button form={token["ethaddress"]+"-"+token["id"]} style={{"height":"30px","paddingTop":"5px"}} className="btn btn-block default-btn-custom" type="submit">Update</button> </td>
                          <td> <a className="btn btn-block default-btn-custom "  rel="nofollow" data-method="delete" style={{"height":"30px","paddingTop":"5px"}} href={"/presales/" + token["id"]}>Destroy</a></td>
                        </tr>
                      )}

                    </tbody>


                    <tfoot className="tfoot-dark">
                      <tr>
                        <th scope="col">Total NFTs ordered (TOTAL NFT demand)</th>
                        <th className="text-center">{this.state.sum}</th>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>


                    <tfoot className="tfoot-dark">
                      <tr>
                        <th scope="col">NB Entries (How many wallets in total) </th>
                        <th className="text-center">{this.state.sumentries}</th>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>

                    <tfoot className="tfoot-dark">
                      <tr>
                        <th scope="col">NB Whitelisted Entries (how many wallets Whitelisted) </th>
                        <th className="text-center">{this.state.sumwhitelistedentries}</th>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>




                <tfoot className="tfoot-dark">
                  <tr>
                    <th scope="col">NB Whitelisted TOKENS  (how many TOKENS Whitelisted)</th>
                    <th className="text-center">{this.state.sumwhitelistedentriesaccepted}</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>



                  </table>
                </div>

                <div className="col-12">
                  <div className="d-flex flex-row pt-3 pb-5 justify-content-center">
                    <a className="btn btn-block default-btn-custom" href="/presales/new" >New Presale</a>
                  </div>

                </div>
              </div>
            </div>
          </section>




          <GoTop />
        </RecoilRoot>
        <FooterMin fixedBottom={true} S3_ADDRESS={this.props.S3_ADDRESS} user_signed_in={this.props.user_signed_in} />
      </React.Fragment>)
  }
}

PreSaleIndexPage.propTypes = {
  artWorksData: PropTypes.array,
  S3_ADDRESS: PropTypes.string
};

export default PreSaleIndexPage
