import React from 'react';
import Web3 from 'web3';



const WithdrawLoading = (props) => {
  return (

      <div className="row justify-content-center pb-5 pt-5">
          <div className="col-1 justify-content-right pr-0">
              <div className="shape-img4 pl-5">
                  <img src={require("shape/shape1.svg")} alt="image" />

              </div>
          </div>
          <div className="col-2 pl-2">
              <p>Loading...</p>
          </div>
    </div>
  )
}

class StartingIndex extends React.Component {

    //Withdrawing function
    async wefinalize() {

        console.log("<-- INSIDE Startingindex --> ")
        console.log("this.props.contractBalance --> ",this.props.contractBalance)

        if(this.props.contract!=='undefined'){
          try{
              1
            await this.props.contract.methods.finalizeStartingIndex().send({from: this.props.account})

          } catch(e) {
            console.log('Error, withdraw: ', e)
          }
        }
      }

    constructor(props) {
       super(props);
       this.state = {
           isToggleOn: true,
           contractBalance: this.props.contractBalance,
           action: "wait"

       };

       // This binding is necessary to make `this` work in the callback    this.handleClick = this.handleClick.bind(this);
     }


render() {
    return (
        <section className="what-we-do-area bg-fafafb pb-100">

            <div className="container ">
            { this.props.ownerAccount != null ?
                    <div className="row">
                        <div className="col-sm-12">

                            <div className="col-md-10 col-md-push-1 col-sm-10 col-sm-push-1">

                                    <div className="feature feature-1 service-1 text-center">

                                        <h4>Starting Index :{ this.props.startingIndex  } </h4><hr/>
                                        <h4>Starting Index Block :{ this.props.startingIndexBlock  } </h4><hr/>

                                        <p align="left"> Just click to finalize the starting Index
                                        </p>


            



                                        <form onSubmit={(e) => {
                                            e.preventDefault()    //doesnt let the page refresh

                                            this.wefinalize()

                                            }}>

                                            <input
                                              type='text'
                                              className="form-control mb1"
                                              placeholder='Nothing to write'
                                              ref={(input) => {this.msize = input }}
                                               />

                                            <input type='submit' className='btn btn-block btn-primary' value ='Finalize Index' />

                                            </form>








                                        <hr/>
                                        </div>

                            </div>

                </div>
                </div>
                : <WithdrawLoading/> }
            </div>


        </section>
    )
}
}

export default StartingIndex;
