import React from "react"
import PropTypes from "prop-types"

import { RecoilRoot } from 'recoil'

import NavbarTwoCustom from './_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
//import { ToastProvider } from 'react-toast-notifications';

import Footer from './_App/Footer';
import FooterMin from './_App/FooterMin';
import GoTop from './_App/GoTop';  //   <NavbarTwoCustom />

class PreSalePostPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <React.Fragment>
                <RecoilRoot>
                    <NavbarTwoCustom />

                    <section className="what-we-do-area bg-fafafb pt-100 pb-2">

                        <div className="container ">

                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <div className=" pb-2 justify-content-center">

                                        <p  className="text-center">   Your pre-sale has been registered.<br />
                                      </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="what-we-do-area  pt-3 pb-3" style={{ "backgroundColor": "#282d41" }}>
                        <div className="container ">

                            <div className="row justify-content-center">
                                <div className="col-10" >
                                    <div className=" d-flex flex-column pt-2 pb-2 justify-content-center">
                                        <p style={{ "color": "white"}} className="text-center">
                                            Now you can go to METAMASK and send <span style={{ "color": "#FF13A7", "fontWeight": "bold", "fontSize": "18px" }}>{this.props.eth_value.toFixed(5)} ETH</span> in TOTAL <br />
                                            (in the next 30 minutes) to the following address:  <br /><a className="custom" href={"https://etherscan.io/address/" + this.props.eth_address} target="_blank" style={{ "fontSize": "18px"}}  >{this.props.eth_address}</a>
                                            <br/>

                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="what-we-do-area bg-fafafb pt-3 pb-100" >
                        <div className="container ">

                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <div className=" pt-2 pb-2 justify-content-center">
                                        <p  className="text-center">
                                        We will mint you <span style={{ "color": "black", "fontWeight": "bold", "fontSize": "18px" }}>{this.props.token_amount} NFTs</span> manually directly <br />to your wallet <span style={{ "color": "black", "fontWeight": "bold", "fontSize": "18px" }}> AT YOUR REGISTERED ADDRESS</span> in the next 24 hours after receiving your payment.

They will then show up in <a  href="/collection" style={{ "fontSize": "18px" }}>"My Collection"</a> and on <a href="https://opensea.io/collection/niftyriots" style={{ "fontSize": "18px" }}>OpenSea</a>.
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>



                    <GoTop />
                </RecoilRoot>
                <FooterMin fixedBottom={true} S3_ADDRESS={this.props.S3_ADDRESS} user_signed_in={this.props.user_signed_in} />
            </React.Fragment>)
    }
}

PreSalePostPage.propTypes = {
    S3_ADDRESS: PropTypes.string
};

export default PreSalePostPage
