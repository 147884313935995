import React from 'react';

const Tokyo = (props) => {
    return (
        <section className="what-we-do-area back-ground-gray pt-5 pb-5">

            <div className="container ">
                <div className="row justify-content-center">


                    <div className="col-md-10 col-md-push-1 col-sm-10 col-sm-push-1">
                        <div className="feature feature-1 service-1 text-left">
                            <h4 className="color-FF13A7" >TOKYO AI COLLECTION</h4>
                            <div className="red-underline mt-3 mb-4"></div>
                        </div>
                    </div>

                    <div className="col-md-9 col-sm-9 ">

                        <div className="feature feature-1 service-1 text-center">

                            <p className="color-FFFFFF" align="left">Everyone who has ever visited Tokyo is in awe with that city. The neon lights, the atmosphere...You can literally feel the electricity in the air on a rainy Autumn day. There is so much energy
                            in that part of the world we had to let the AI create its own vision of the city. This is a ultra limited edition of just 150/200 works created in collaboration with DagostinoAI (@dagostinoAI in Instagram). Here are a few sneak peaks.
                            We feel this will be awesome.
                            <br></br>Discord: <a className="color-FF13A7" href="https://discord.gg/6gWXvGF4gr"><b>NIFTYRIOTS</b></a>
                            </p>

                            <hr />
                        </div>

                    </div>


                </div>

                <div className="row justify-content-center pt-1 pb-5">

                    <div className="col-lg-3 col-md-3 pb-5 "  >
                        <div className="d-flex flex-column ">
                            <div className="d-flex justify-content-center ">
                                <div className="image " style={{ maxWidth: "80%" }}>
                                    <img src={"https://mkokeshi.s3.ap-southeast-1.amazonaws.com/webimages/tokyo1.png"} />
                                </div>
                            </div>

                            <div className="d-flex justify-content-center pt-4"><h5 className="color-FF13A7">Salarymen</h5> </div>

                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 pb-5 "  >
                        <div className="d-flex flex-column ">
                            <div className="d-flex justify-content-center ">
                                <div className="image " style={{ maxWidth: "80%" }}>
                                    <img src={"https://mkokeshi.s3.ap-southeast-1.amazonaws.com/webimages/tokyo2.png"} />
                                </div>
                            </div>

                            <div className="d-flex justify-content-center pt-4"><h5 className="color-FF13A7">Shinjuku</h5> </div>

                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 pb-5 "  >
                        <div className="d-flex flex-column ">
                            <div className="d-flex justify-content-center ">
                                <div className="image " style={{ maxWidth: "80%" }}>
                                    <img src={"https://mkokeshi.s3.ap-southeast-1.amazonaws.com/webimages/tokyo3.png"} />
                                </div>
                            </div>

                            <div className="d-flex justify-content-center pt-4"><h5 className="color-FF13A7">Kita Senju</h5> </div>

                        </div>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default Tokyo;
