import React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';


const FAQ = () => {
    return (
        <section className="what-we-do-area bg-fafafb pt-100 pb-70">


            <div className="container ">
                <div className="section-title mb-3">
                    <div className="row justify-content-center">
                        <div className="col-12 pt-3">
                            <span className="sub-title cella-font" style={{ fontSize: "28px", color: '#FF13A7' }}>
                                FAQ
                                </span>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-12 pb-5 border-line-bottom">
                        <div className="faq-accordion">
                            <Accordion allowZeroExpanded preExpanded={['a', 'b', 'c', 'd','e']}>
                                <AccordionItem uuid="a">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Q1. How to buy?
                                </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <a href="/how_to_buy" className="">See Details.</a>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem uuid="b">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Q2. How to add token to Metamask?
                                </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <a href="/how_to_add_contract" className="">See Details.</a>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem uuid="c">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Q3. Where can I sell NiftyRiots after buying them?
                                </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>

                                    <p className="py-3">Please check the Official NiftyRiots collection On Open Sea</p>
                                        <a href="https://opensea.io/collection/niftyriots" target="_blank" className="">https://opensea.io/collection/niftyriots</a>


                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem uuid="d">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Q4. When are the NFTs revealed?
                                </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p className="py-3">They are revealed instantly after the purchase.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem uuid="e">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Q5. When is the Drop?
                                </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p className="py-3">Please check back in with us 20thSEP 22.00UTC - Please check Discord for more.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default FAQ;
