import React from 'react';

const Genblockchain = (props) => {
    return (
        <section className="what-we-do-area back-ground-gray pt-5 pb-5">

            <div className="container ">
                <div className="row justify-content-center">


                    <div className="col-md-10 col-md-push-1 col-sm-10 col-sm-push-1">
                        <div className="feature feature-1 service-1 text-left">
                            <h4 className="color-FF13A7" >AI - BLOCKS GENERATIVE ON THE BLOCKCHAIN</h4>
                            <div className="red-underline mt-3 mb-4"></div>
                        </div>
                    </div>

                    <div className="col-md-9 col-sm-9 ">

                        <div className="feature feature-1 service-1 text-center">

                            <p className="color-FFFFFF" align="left">This step is to bring AI generative art on the blockchain. We are creating the tech which will allow to do that and keeping it well under the radar. We will be able to create abstract art using the user input and much more.
                            <br></br>Discord: <a className="color-FF13A7" href="https://discord.gg/6gWXvGF4gr"><b>NIFTYRIOTS</b></a>
                            </p>

                            <hr />
                        </div>

                    </div>


                </div>

                <div className="row justify-content-center pt-1 pb-5">


                    <div className="col-lg-3 col-md-3 pb-5 "  >
                        <div className="d-flex flex-column ">
                            <div className="d-flex justify-content-center ">
                                <div className="image " style={{ maxWidth: "120%" }}>
                                    <img src={ "https://mkokeshi.s3.ap-southeast-1.amazonaws.com/webimages/abstract1.png"} />
                                </div>
                            </div>

                            <div className="d-flex justify-content-center pt-4"><h5 className="color-FF13A7">Abstract Art generated on the blockchain</h5> </div>

                        </div>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default Genblockchain;
