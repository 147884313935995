import React from 'react';


const Services = (props) => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={require("star-icon.png")} alt="image" />
                        Key Ideas
                    </span>
                    <h2>What makes NiftyRiots unique?</h2>
                    <p>Let's introduce some of the features for this Art project.</p>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={require("services/service-icon1.png")} alt="image" />
                            </div>
                            <h3>
                            {props.reloaded ?
                                <a href="/whitepaper_reloaded#ai" target="_parent" >Made With A.I.</a>
                                :
                                <a href="/whitepaper#ai" target="_parent" >Made With A.I.</a>
                            }

                            </h3>
                            <p>We use Artificial Intelligence to create the art for those NFTs. On this scale, this is a first.</p>

                            {props.reloaded ?
                                <a href="/whitepaper_reloaded#ai" target="_parent" className="read-more-btn">Read More <i className="flaticon-right"></i></a>
                            :
                                <a href="/whitepaper#ai" target="_parent" className="read-more-btn">Read More <i className="flaticon-right"></i></a>
                            }
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={require("services/service-icon2.png")} alt="image" />
                            </div>
                            <h3>
                                {props.reloaded ?
                                <a href="/whitepaper_reloaded#create" target="_parent"  >You Can Create</a>
                                :
                                <a href="/whitepaper#create" target="_parent"  >You Can Create</a>
                                }
                            </h3>
                            <p>We will release the weights for the neural network which generates this art. You can create new art too.</p>
                            {props.reloaded ?
                            <a href="/whitepaper_reloaded#create" target="_parent" className="read-more-btn">Read More <i className="flaticon-right"></i></a>
                            :
                            <a href="/whitepaper#create" target="_parent" className="read-more-btn">Read More <i className="flaticon-right"></i></a>
                            }
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={require("services/service-icon4.png")} alt="image" />
                            </div>
                            <h3>
                            {props.reloaded ?
                                <a href="/whitepaper_reloaded#collectible" target="_parent" >Highly Collectible</a>
                            :
                                <a href="/whitepaper#collectible" target="_parent" >Highly Collectible</a>
                            }
                            </h3>
                            <p>You think other NFT collections are repetitive? NiftyRiots are ALL different, check out the gallery and enjoy.</p>

                            {props.reloaded ?
                            <a href="/whitepaper_reloaded#collectible" target="_parent" className="read-more-btn">Read More <i className="flaticon-right"></i></a>
                            :
                            <a href="/whitepaper#collectible" target="_parent" className="read-more-btn">Read More <i className="flaticon-right"></i></a>
                            }
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={require("services/service-icon5.png")} alt="image" />
                            </div>
                            <h3>
                            {props.reloaded ?
                                <a href="/whitepaper_reloaded#art" target="_parent" >Art Avant-Garde</a>
                            :
                                <a href="/whitepaper#art" target="_parent" >Art Avant-Garde</a>
                            }
                            </h3>
                            <p>Our Artistic Director is a leader in A.I. art since 2018. We will give more details after the launch.</p>

                            {props.reloaded ?
                            <a href="/whitepaper_reloaded#art" target="_parent" className="read-more-btn">Read More <i className="flaticon-right"></i></a>
                            :
                            <a href="/whitepaper#art" target="_parent" className="read-more-btn">Read More <i className="flaticon-right"></i></a>
                            }
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={require("services/service-icon3.png")} alt="image" />
                            </div>
                            <h3>
                            {props.reloaded ?
                                <a href="/whitepaper_reloaded#own" target="_parent"  >You Own</a>
                                :
                                <a href="/whitepaper#own" target="_parent"  >You Own</a>
                            }

                            </h3>
                            <p>As the NFT owner on the ethereum blockchain, you can enjoy the image for commercial or private usage.</p>

                            {props.reloaded ?

                            <a href="/whitepaper_reloaded#own" target="_parent" className="read-more-btn">Read More <i className="flaticon-right"></i></a>
                            :
                            <a href="/whitepaper#own" target="_parent" className="read-more-btn">Read More <i className="flaticon-right"></i></a>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Services;
